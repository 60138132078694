/* src/components/HomePage.css */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

.hone-page {
    height: 100%;
    margin: 0;
    padding: 0;
  }

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  background-color: #f4f1eb;
}

.content {
  max-width: 350px; 
  width: 100%;
  justify-content: left;
}

h1 {
  font-family: 'Libre Baskerville', serif;
  font-size: 38px;
  color: #333;
}

.hometext {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #666;
  letter-spacing: -.015rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.action-button {
  font-family: 'Libre Baskerville', serif;
  color: #333;
  background-color: #D8CCC0;
  border: none;
  border-radius: 20px;
  padding: 20px;
  width: 48%;
  aspect-ratio: 1 / 1; /* Makes the button square */
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  margin-top: 16px;
  line-height: 1.3;
  /* font-weight: 600; */
  letter-spacing: -.01rem;
  border: 2px solid #DDD3C8;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.03) inset, 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
}

.action-button:hover {
  background-color: #c8bcb0;
  color: #666;
}

/* .icon-home {
  font-size: 32px;
  margin-top: 20px;
} */

/* .chat-input-home {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-top: 20px;
} */

.spacer {
    height: 5vh;
    flex-shrink: none;
}