/* src/AuthModal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #f4f1eb;
  padding: 40px;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-content h2 {
  margin-bottom: 24px;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content input {
  padding: 12px 16px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 24px;
  background-color: #fff;
}

.modal-content button {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  background-color: #333;
  color: #fff;
  border-radius: 24px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background-color: #555;
}


.modal-content .message {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

@media (max-width: 480px) {
  .modal-content {
    padding: 30px;
    max-width: 80%;
  }

  .modal-content h2 {
    font-size: 24px;
  }

  .modal-content input,
  .modal-content button {
    font-size: 14px;
  }
}