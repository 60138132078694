/* src/components/MeditatePage.css */
.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* z-index: -1; Send the video behind other content */
    z-index: 1; /* Ensure the container is above the background */
}

.fade-to-black {
    background-color: black; /* Transition to black background */
}
  
.video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: opacity 2s ease-out; /* Fade out transition */
}

  .start-button {
    font-family: 'Libre Baskerville', serif;
    color: #333;
    background-color: #D8CCC0;
    border: none;
    border-radius: 20px;
    padding: 20px;
    width: 48%;
    aspect-ratio: 1 / 1; /* Makes the button square */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    margin-top: 16px;
    margin-bottom: 20px;
    line-height: 1.3;
    letter-spacing: -.01rem;
    border: 2px solid #DDD3C8;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.03) inset, 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  }
  
  .start-button:hover {
    background-color: #c8bcb0;
    color: #666;
  }
  
  .controls {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10; /* Ensure the controls are above other content */
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Added background for better visibility */
    padding: 10px; /* Added padding for better usability */
   
  }

  
  .timer {
    color: white;
    font-size: 24px;
    margin-right: 6px;

  }
  
  .control-button {
    margin-left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    z-index: 10;
    border-radius: 8px;
    width: 70px;
    border: solid rgba(255, 255, 255, 0.3) 1px;
    justify-content: center;
    align-items: center;

  }
  
  .control-button:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .finish-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 3em;
    z-index: 2; /* Ensure the message is above other content */
  }
  
  .fade-out {
    opacity: 0; /* Fade out effect */
  }
  
  .back-arrow {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 10px;
    height: 15px;
    cursor: pointer;
    z-index: 10; /* Ensure the back arrow is above other content */
  }

  .popover {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    z-index: 3;
    text-align: center;
    background-color: #f4f1eb;
    max-width: 400px;
    width: 80%;
  }
  
  .popover-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .popover h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 24px;
    color: #fff;
    margin: 0 0 10px 0;
    line-height: 1.5;
    color: #333;
    margin-top: 17px;
  }
  
  .popover p {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 8px;
    color: #666;
    letter-spacing: -.015rem;
  }

  .button-container-meditate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
  }

  .action-button-meditate {
    font-family: 'Libre Baskerville', serif;
    color: #333;
    background-color: #D8CCC0;
    border: none;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    aspect-ratio: 1 / 1; /* Makes the button square */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    margin-top: 16px;
    line-height: 1.3;
    /* font-weight: 600; */
    letter-spacing: -.01rem;
    border: 2px solid #DDD3C8;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.03) inset, 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  }
  
  .action-button-meditate:hover {
    background-color: #c8bcb0;
    color: #666;
  }
  