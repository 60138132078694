/* src/App.css */

body, html, #root, .App {
  height: 100%;
  height: -webkit-fill-available;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f1eb;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 20px;
}

.markdown-content {
  font-size: 16px;
  line-height: 1.6;
}

.markdown-content p {
  margin-bottom: 1em;
}

.markdown-content ul,
.markdown-content ol {
  margin-bottom: 1em;
  padding-left: 2em;
}

.markdown-content li {
  margin-bottom: 0.5em;
}

.markdown-content strong {
  font-weight: bold;
}

.chat-message {
  margin-bottom: 20px;
  padding: 12px 16px;
  border-radius: 8px;
}

.chat-message.user {
  background-color: #f0f0f0;
  text-align: right;
}

.chat-message.tutor {
  text-align: left;
}

.chat-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.chat-history {
  flex-grow: 1;
  padding: 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.current-question {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px dotted #e0e0e0;
  line-height: 1.5;
}

.current-question ul {
  padding-left: 20px;
  line-height: 1.5;
}

.chat-input {
  display: flex;
  align-items: flex-end;
  padding: 16px 24px;
  background: #f9f9f9;
  border-top: 1px solid #e0e0e0;
}

.chat-input textarea {
  flex-grow: 1;
  margin-right: 20px;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 12px;
  resize: none;
  overflow-y: auto;
  min-height: 20px;
  max-height: 100px;
  line-height: 1.2;
  box-sizing: border-box;
  align-content: center;
}

.chat-input button {
  flex-shrink: 0;
  padding: 22px 24px;
  font-size: 16px;
  border: none;
  background-color: #333;
  color: #fff;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-end;
  min-height: 62px;
}

.chat-input button:hover {
  background-color: #555;
}

/* Safari-specific fixes */
@supports (-webkit-touch-callout: none) {
  .chat-container {
    height: -webkit-fill-available;
  }

  .chat-history {
    height: calc(100% - 120px); /* Adjust based on your input area height */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .chat-input {
    position: sticky;
    bottom: 0;
    background: #f9f9f9;
    z-index: 1000;
  }
}

/* Desktop-specific styles */
@media (min-width: 769px) {
  .chat-container {
    width: 100%;
    max-width: 800px;
    height: 90vh;
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .chat-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
    max-width: none;
  }

  .chat-history {
    height: calc(100% - 120px);
    -webkit-overflow-scrolling: touch;
  }

  .chat-input {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
  }

}


/* Onboarding styles */
.onboarding-cards {
  background-color: #d8ccc1;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.onboarding-cards h2 {
  font-family: libre baskerville;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.onboarding-cards p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #666;
}

.onboarding-cards input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 20px;
}

.onboarding-cards button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.onboarding-cards button:hover {
  background-color: #555;
}

.tool-call-indicator {
  background-color: #f0f0f0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  color: #333;
}